<template>
  <div class="box">
    <van-sticky>
      <van-search
        style="cursor: pointer"
        v-model="listfrom.title"
        placeholder="请选择筛查条件"
        disabled
        shape="round"
        @click="orderClick"
      >
        <!-- <template #action>
          <div @click="onSearch">搜索</div>
        </template>
        <template #left-icon>
          <img
            src="http://jmylapi.zhsq.cloud//upload/files/2022/11/ZR2uIACzwrnj6968bb9baa4a2b20d6e27daf88a25a15.png"
            style="
              width: 16px;
              height: 16px;
              margin-right: 10px;
              margin-top: 5px;
            "
          />
        </template> -->
      </van-search>
    </van-sticky>
    <!-- 顶部图片 -->
    <div class="topBg"></div>
    <!-- tabs切换 -->
    <!-- <van-tabs :active="type" sticky @change="CateIdClick">
      <van-tab
        v-for="item in tabList"
        :key="item.STypeId"
        :title="item.Name"
        :name="item.STypeId"
        color="#ff4500"
        background="#ff4500"
      >
      </van-tab>
    </van-tabs> -->
    <!-- 列表 -->
    <!-- 没有更多了 -->
    <van-empty v-if="!list.length" description="─── 暂无房源列表 ───" />
    <div class="lists" v-else>
      <div
        class="list"
        v-for="item in list"
        :key="item.id"
        @click="gotoUrl('/guanghe/index/' + item.LeaseId)"
      >
        <div style="display: flex; border-bottom: 1px solid #f4f4f4">
          <div class="listImg">
            <img
              v-if="item.Imgs"
              :src="item.Imgs.split(',')[0]"
              style="width: 100%; height: 100%; border-radius: 2px"
            />
            <span v-else>暂无</span>
          </div>
          <div class="listTitle">
            <span style="height: 44%">{{ item.RoomSource }}</span>
            <div class="listLabel">
              <div
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                "
              >
                {{ item.RoomType }}·{{ item.Area }}m²·{{ item.Address }}
              </div>
              <div class="money">
                {{ Number(item.Price) }}
                <i style="color: #fc4839; font-size: 13px; font-style: normal"
                  >元/月</i
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 购物车 -->
    <!-- <div class="shopping" @click="shoppingClick()" v-if="shoppongShow">
      <img
        src="../../assets/shop/shopping.png"
        alt=""
        style="width: 60%; margin: 12px 17%"
      />
    </div> -->
    <div>
      <van-popup
        v-model="shoppingShow"
        round
        position="bottom"
        :style="{ height: '70%' }"
      >
        <!-- 商品列表 -->
        <div class="goodsLists">
          <div
            class="goodsList"
            v-for="item in shoppingList"
            :key="item.GoodsId"
          >
            <div style="width: 32%">
              <img
                :src="item.GoodsImg"
                style="width: 110px; height: 100px; margin: 10px"
              />
            </div>
            <div style="width: 65%">
              <div class="flex">
                <span style="color: #01a0fb; font-weight: 700">{{
                  item.GoodsName
                }}</span>
                <span>{{ item.Price }}/{{ item.MUnit }}</span>
              </div>
              <div class="flex">
                <span style="color: #ee0c24">¥{{ item.Amount }}</span>
                <span>
                  <van-stepper
                    v-model="item.GoodsNum"
                    theme="round"
                    min="0"
                    button-size="22"
                    disable-input
                    @change="Goodschange(item)"
                    style="display: inline-block"
                  />
                </span>
              </div>
              <div class="flex">
                <button @click="goodsDelete(item.OGoodsId)" class="delete">
                  删除
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 结算按钮 -->
        <div class="button">
          <button @click="orderClick()">结 算</button>
        </div>
      </van-popup>
    </div>
    <!-- 收货地址 -->
    <van-popup
      v-model="addressShow"
      round
      position="top"
      :style="{ height: '42%' }"
    >
      <!-- <van-field
        v-model="listfrom.OrganName"
        label="社 区"
        readonly
        placeholder="请选择所在地区"
        @click="show = true"
      /> -->
      <van-cell-group>
        <van-field
          v-model="listfrom.leaseModeDesc"
          @click="sortIdShow = true"
          label="出租方式"
          readonly="readonly"
          placeholder="请选择出租方式"
        />
        <van-popup v-model="sortIdShow" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="sortIdlist"
            value-key="name"
            @cancel="sortIdShow = false"
            @confirm="onsortId"
          >
          </van-picker>
        </van-popup>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="listfrom.roomType"
          label="房 型"
          placeholder="请输入房型"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="listfrom.minPrice"
          label="最低租金"
          placeholder="请输入最低租金"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="listfrom.maxPrice"
          label="最高租金"
          placeholder="请输入最高租金"
        />
      </van-cell-group>
      <div
        style="display: flex; justify-content: space-evenly; margin-top: 20px"
      >
        <van-button
          class="submit"
          style="background-color: #1989fa"
          @click="reset"
          >重 置</van-button
        >
        <van-button class="submit" @click="onSearch">搜 索</van-button>
      </div>
    </van-popup>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="orgonList"
        @close="show = false"
        :field-names="{
          text: 'OName',
          value: 'OCode',
          children: 'Children',
        }"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>
<script>
import { WeMyMemberInfo, WxGetLeasePage } from "@/api/RealInfo";
import {
  WeGenerateOrder,
  WeGetMyOrderPage,
  WeGetOrderGoodsList,
  WePlaceOrder,
  WeDeleteOrderGoods,
  WeSaveOrderService,
  WeGetMyBindInfoPage,
} from "@/api/shop";
import { getOpenId, setOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { GetChildOrgansNullToken, WeGetOrganTree } from "@/api/Organ";
Vue.use(Toast);
Vue.use(Dialog);
export default {
  data() {
    return {
      orgonList: [],
      sortIdShow: false,
      listfrom: {
        page: 1,
        limit: 10,
        leaseMode: "",
        leaseModeDesc: "",
        roomType: "",
        OrganName: "",
        orgCode: this.$route.params.id,
        minPrice: "",
        maxPrice: "",
      }, //查询传参
      infoId: this.$route.params.id,
      shopType: "", //商铺类型
      type: 29, //tabs选中
      tabList: [], //tabs类型
      list: [
        {
          Logo: "https://dximg.dexian.ren/111.png",
          Name: "九鼎中和湾三室精装 周边配套完善观河景 环境舒适 地铁口",
          WorkTime: "2室·93.13m²·九鼎中和湾·古城路",
          Address: "1700",
        },
      ], //商品列表
      integralNum: 0,
      shoppingList: [], //购物车列表
      shoppingShow: false, //购物车弹窗
      order: "", //订单
      addressShow: false, //收货地址
      shoppongShow: false, //购物车按钮
      addressList: [], //收货地址
      checked: "0", //收货地址选择
      integralCk: "1", //积分选择
      CurIntegral: "", //会员当前积分
      PmCurIntegral: "", //党员当前积分
      VolCurIntegral: "", //志愿者当前积分（时间币）
      totalNum: 0, //总商品数
      totalGold: 0, //总金额
      show: false,
      cascaderValue: "",
      sortIdlist: [
        {
          value: 1,
          name: "整租",
        },
        {
          value: 2,
          name: "合租",
        },
      ],
    };
  },
  mounted() {
    // setOpenId("oas2d4qNkpHqYBS0NlA4glPsVR1k"); //测试openid
    // this.getTabsList();
    this.getList();
    this.getMemberByOpenID();
    this.getOrgonTree();
    // this.getUserOrder();
  },
  methods: {
    reset() {
      this.listfrom = {
        page: 1,
        limit: 10,
        leaseMode: "",
        leaseModeDesc: "",
        roomType: "",
        OrganName: "",
        orgCode: "",
        minPrice: "",
        maxPrice: "",
      };
    },
    onFinish: function (val) {
      this.show = false;
      console.log(val);
      this.fieldValue = val.selectedOptions
        .map((option) => option.OName)
        .join("/");
      this.listfrom.OrganName = this.fieldValue;
      this.OCode = val.value;
      // this.SerOrganList = []
      // this.getSerOrganList()
      // this.getSerOrganCountSearch(this.OCode)
    },
    // 组织机构
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
        this.listfrom.OrganName = res.data.data[0].OName;
        this.oCode = res.data.data[0].OName;
        this.listfrom.orgCode = res.data.data[0].Children[0].OCode;
        let Code = this.getAllIds(this.orgonList);
        Code.forEach((i) => {
          if (this.cascaderValue == i.OCode) {
            this.listfrom.OrganName = i.OName;
          }
        });
      });
    },
    onsortId(val) {
      console.log(val);
      this.listfrom.leaseMode = val.value;
      this.listfrom.leaseModeDesc = val.name;
      this.sortIdShow = false;
    },
    // onSearch
    onSearch() {
      console.log(this.listfrom, "onSearchonSearch");
      this.list = [];
      this.listfrom.page = 1;
      this.addressShow = false;
      // this.listfrom.title = val;
      this.getList();
    },
    onCancel() {
      this.list = [];
      this.listfrom.page = 1;
      this.listfrom.title = "";
      this.getList();
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // 选择商品
    orderClick() {
      this.addressShow = true;
      // WeGetMyBindInfoPage({ openID: getOpenId() }).then((res) => {
      //   if (res.data.count == 0) {
      //     Dialog.confirm({
      //       title: "提醒",
      //       message: "您还没有添加收货地址,是否前往添加!",
      //     })
      //       .then(() => {
      //         if (window.__wxjs_environment === "miniprogram") {
      //           window.wx.miniProgram.navigateTo({
      //             url: "../my/user/oldManList/index?name='收货地址'",
      //           });
      //         } else {
      //           alert("请在小程序中打开");
      //         }
      //       })
      //       .catch(() => {
      //         return false;
      //       });
      //   } else {
      //     this.addressList = res.data.data;
      //   }
      // });
    },
    // 步进器选择
    Goodschange(row) {
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.order,
        GoodsId: row.GoodsId,
        GoodsNum: row.GoodsNum,
      }).then((res) => {
        Toast(row.GoodsName + ":" + row.GoodsNum);
        this.getshoppingList(this.order);
      });
    },
    // 获取订单情况
    getUserOrder() {
      console.log(this.list);
      return;
      WeGetMyOrderPage({ LeaseId: 74, openID: getOpenId(), status: 1 }).then(
        (res) => {
          if (res.data.code != 0) {
            Toast.fail(res.data.msg);
            this.shoppongShow = false;
          } else {
            if (res.data.count == 0) {
              // 没有订单 生成订单
              WeGenerateOrder({ LeaseId: 74, OpenID: getOpenId() }).then(
                (res) => {
                  this.getshoppingList(res.data.data);
                  this.order = res.data.data;
                  this.shoppongShow = true;
                }
              );
            } else {
              // 有订单使用现有订单
              this.getshoppingList(res.data.data[0].OrderId);
              this.order = res.data.data[0].OrderId;
              this.shoppongShow = true;
            }
          }
        }
      );
    },
    // 获取购物车列表
    getshoppingList(row) {
      this.totalNum = 0;
      this.totalGold = 0;
      WeGetOrderGoodsList({ openID: getOpenId(), orderId: row }).then((res) => {
        this.shoppingList = res.data.data;
        for (let i = 0; i < this.shoppingList.length; i++) {
          this.totalNum += this.shoppingList[i].GoodsNum;
          this.totalGold += this.shoppingList[i].Amount;
        }
      });
    },
    // 购物车点击
    shoppingClick() {
      this.shoppingShow = true;
      this.getshoppingList(this.order);
    },
    // 切换分类
    // CateIdClick(row) {
    //   console.log(row);
    //   this.type = row;
    //   this.getList();
    // },
    // 获取不同分类列表
    // getTabsList() {
    //   console.log(3243454);
    //   WxGetShopTypeList({ shopCate: 2 }).then((res) => {
    //     this.tabList = res.data.data;
    //     console.log(this.tabList, ":this.tabList ");
    //   });
    // },
    // 获取列表
    getList() {
      var cateId = this.type;
      WxGetLeasePage(this.listfrom).then((res) => {
        console.log(res, 11111111111);
        this.list = res.data.data;
        // console.log("this", this.list);
        // this.shopType = this.list[0].STypeId;
        // console.log(this.shopType, "this.shopType ");
      });
    },
    // 获取会员信息
    getMemberByOpenID() {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == 0) {
          var data = res.data.data;
          console.log(data, "积分");
          this.integralNum = data.CurIntegral;
          this.CurIntegral = data.CurIntegral;
          this.PmCurIntegral = data.PmCurIntegral;
          this.VolCurIntegral = data.VolCurIntegral;
        } else {
          Toast.fail("您还不是会员,请先注册会员!");
        }
      });
    },
    // 下单结算
    PlaceOrder() {
      if (this.checked == 0) {
        var DeliveryMode = 2;
        this.checked = undefined;
      } else {
        var DeliveryMode = 1;
      }
      if (this.totalGold > this.integralNum) {
        Toast.fail("积分不足,无法兑换商品!");
        return false;
      }
      WePlaceOrder({
        OpenID: getOpenId(),
        ScoreType: this.integralCk,
        BindId: this.checked,
        DeliveryMode: DeliveryMode,
        OrderId: this.order,
        GoodsScore: this.totalGold,
        // GoodsNum:this.totalNum,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("提交成功!");
          this.addressShow = false;
          this.shoppingShow = false;
          this.getMemberByOpenID();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 删除单独商品
    goodsDelete(row) {
      WeDeleteOrderGoods({
        OpenID: getOpenId(),
        OrderId: this.order,
        OGoodsId: row,
      }).then((res) => {
        if (res.data.code == 0) {
          this.getshoppingList(this.order);
          this.shoppingShow = false;
          Toast.success("删除成功!");
        }
      });
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style scoped>
.box {
  background: #fff;
  /* height: 100%; */
  min-height: 100vh;
}

/* 背景 */
.topBg {
  position: relative;
  margin: auto;
  height: 90px;
  width: 92%;
  background: url("https://dximg.dexian.ren/banner.png") no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 10px;
}

/* 列表 */
.lists {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.lists .list {
  width: 92%;
  margin: auto;
}

.lists .listImg {
  width: 31.5%;
  height: 85px;
  text-align: center;
  margin: 10px 3% 16px;
}

.listTitle {
  flex: 1;
  margin: 12px 0;
}

.listTitle span:nth-child(1) {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.listLabel div:nth-child(1) {
  /* margin-bottom: 1px; */
}

.listLabel {
  margin-top: 6px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

.money {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fc4839;
}
/* 购物车 */
.shopping {
  position: fixed;
  right: 15px;
  bottom: 25px;
  width: 65px;
  height: 65px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #f5eeee;
}
.goodsLists {
  width: 95%;
  margin: 10px auto 10px;
  background: #fff;
  height: 57vh;
  border-radius: 10px;
  overflow-y: scroll;
}
.goodsList {
  display: flex;
  justify-items: center;
  /* align-items: center; */
  background: #fff;
  border-radius: 10px;
  margin-bottom: 7px;
}
.button {
  width: 100%;
  text-align: center;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.button button {
  position: fixed;
  bottom: 15px;
  width: 80%;
  height: 40px;
  line-height: 40px;
  background: #01a0fb;
  color: #fff;
  border: none;
  margin: 8px auto 15px;
  border-radius: 6px;
  left: 10%;
}
.goodsList .delete {
  background: #ee0c24;
  border: none;
  color: #fff;
  font-size: 14px;
  border-radius: 7px;
  padding: 5px 13px;
}
.address .van-radio {
  background: #f5f5f5;
  width: 80%;
  margin: 10px auto;
  padding: 6px 15px;
  border-radius: 6px;
}
.address .radioBox {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  font-size: 14px;
}
.integralBox {
  position: absolute;
  bottom: 81px;
  font-size: 12px;
  margin-left: 10%;
}
.submit {
  width: 45%;
  height: 40px;
  background: #fc4839;
  border-radius: 22px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>